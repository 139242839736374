import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screenskip1 } from '../../../components/images/support/tasks/Screenskip1.generated';
import { Screenskip2 } from '../../../components/images/support/tasks/Screenskip2.generated';
import { Screenskip3 } from '../../../components/images/support/tasks/Screenskip3.generated';
import { Screenskip4 } from '../../../components/images/support/tasks/Screenskip4.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "skipping-tasks-"
    }}>{`Skipping Tasks 📋✔`}</h1>
    <p>{`This section shows how to skip a Task in a Workflow. `}</p>
    <p>{`Sometimes when we assign a Workflow to an employee there may be certain Tasks in that Workflow that we do not want the employee to complete.`}</p>
    <p>{`We can skip these Tasks so that the employee can complete the workflow without having to do the tasks we mark as skipped.`}</p>
    <p>{`Let's learn how to skip a Task.`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`From the `}<strong parentName="li">{`Users`}</strong>{` menu. Click into a Workflow that has been assigned to an employee.`}</li>
    </ol>

    <Screenskip1 mdxType="Screenskip1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Select the Task you wish to skip then click the menu button in the Task preview.`}</li>
    </ol>

    <Screenskip2 mdxType="Screenskip2" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Then click `}<strong parentName="li">{`Skip task`}</strong>{`.`}</li>
    </ol>

    <Screenskip3 mdxType="Screenskip3" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`We can now see that the task has been skipped. The employee will not have to complete this task.`}</li>
    </ol>

    <Screenskip4 mdxType="Screenskip4" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      